<template>
  <div class="page-wrapper" :class="{ 'is-usa': $route.query && $route.query.market === 'usa' }">
    <div v-if="$route.query && $route.query.market === 'usa' && marketPrice" class="usa">
      <img src="@/assets/img/usa-flag.png" alt="USA" />
      <p class="small-title">$1 USD = ${{ parseFloat(marketPrice, 2).toFixed(2) }} CAD + 25% discount = <span>big savings*</span></p>
    </div>

    <header class="main-header" :class="{ 'covid-actif': $route.query && $route.query.market === 'usa' }">
      <div class="left">
        <img src="@/assets/img/logo.svg" class="logo" alt="Logo Tremblant" />
        <img v-if="$route.meta.lang === 'fr'" src="@/assets/img/tagline-fr.svg" class="tagline" alt="Tagline Tremblant" />
        <img v-else-if="$route.meta.lang === 'en'" src="@/assets/img/tagline-en.svg" class="tagline" alt="Tagline Tremblant" />
      </div>
      <div class="right">
        <div class="links">
          <a href="tel:18887381777">1-888-738-1777</a>
          <p>|</p>
          <router-link v-if="pageData && $route && $route.meta.lang === 'fr'" :to="{ name: 'pageEn', params: { pageData } }" class="lang">EN</router-link>
          <router-link v-else-if="pageData && $route && $route.meta.lang === 'en'" :to="{ name: 'page', params: { pageData } }" class="lang"
            >FR</router-link
          >
        </div>
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
      <a href="tel:18887381777" class="phone-mobile"><img src="@/assets/img/phone2.svg" alt="Call Tremblant" /></a>
      <div class="mobile-cta">
        <a href="#" class="cta red" @click.prevent="showlodgingform">{{ $t('site.reservez-long') }}</a>
      </div>
    </header>

    <section class="hero ete_2022_bighit_dink ete_2022">
      <div class="img-mobile"></div>
      <div class="deal-wrap" data-inview="slideLeft" data-delay="100">
        <div class="top">
          <img src="@/assets/img/curve-yellow.svg" alt="Curve" />
          <div class="data"><h2 class="big-title" v-html="$t('site.slogan')"></h2></div>
        </div>
        <div class="bottom">
          <div class="deal">
            <h2 class="small-title" v-html="$t('site_ete_2022.bh_lodging_hero')"></h2>
            <a href="#" class="cta blanc-bleu" @click.prevent="showlodgingform">{{ $t('site.reservez-short') }}</a>
            <a href="#" class="regular-text" @click.prevent="showdealpanel">{{ $t('site.show-details') }}</a>
          </div>
          <div class="deal arguments">
            <div class="argument">
              <img src="@/assets/img/icon-smile.svg" alt="Icon smile" />
              <p class="regular-text">{{ $t('site_ete_2022.avantage_1') }}</p>
            </div>
            <div class="argument">
              <img src="@/assets/img/icon-calendar.svg" alt="Icon calendar" />
              <p class="regular-text">{{ $t('site_ete_2022.avantage_2') }}</p>
            </div>
            <div class="argument">
              <img src="@/assets/img/icon-return.svg" alt="Icon smile" />
              <p class="regular-text">{{ $t('site_ete_2022.avantage_3') }}</p>
            </div>
          </div>
          <p class="cut-off">{{ $t('site_ete_2022.bh_lodging_cut') }}</p>
        </div>
      </div>
    </section>

    <section class="quoi_faire_wrap" id="quoi-faire">
      <img src="@/assets/img/curve-bleu-big.svg" class="curve" alt="Courbe" />
      <h2 class="medium-title" data-inview="fadeInUp" data-delay="200">{{ $t('site_ete_2022.experience_title') }}</h2>

      <!-- CARROUSEL EXPERIENCES -->
      <div class="quoi_faire_bloc_sroll">
        <div v-for="(item, i) in $t('dink_ete_2022_experiences')" :key="i" class="quoi_faire_bloc" data-inview="fadeInUp" :data-delay="4 + 1 + '00'">
          <div class="img">
            <div class="overlay"></div>
            <img :src="item.img" alt="Hébergement Tremblant" />
          </div>
          <div class="data">
            <p id="counter4" class="number" :data-count-up="item.number">{{ item.number }}</p>
            <p class="small-title" v-html="item.titre"></p>
            <a
              href="#"
              class="cta blanc-bleu"
              @click.prevent="
                toggleDynamicSidePanel(
                  true,
                  $t('dink_ete_2022_experiences').filter((i) => calendarActiveFilter === 'Tous' || calendarActiveFilter === 'All' || i.filter === calendarActiveFilter),
                  i
                )
              "
              >{{ $t('site_ete_2022.more') }}</a
            >
          </div>
        </div>
      </div>
    </section>

    <section class="calendar_events" id="events">
      <h2 class="medium-title" data-inview="fadeInUp" data-delay="200" v-html="$t('site_ete_2022.event_title')"></h2>
      <div class="filters_wrap" data-inview="fadeInUp" data-delay="300">
        <div
          v-for="(item, i) in $t('dink_ete_2022.calendar_filters')"
          :key="i"
          class="filter"
          :class="{ active: calendarActiveFilter === item }"
          @click.prevent="togglecalendarFiler(item)"
        >
          <p class="small-title">{{ item }}</p>
        </div>
      </div>
      <div class="events_list" data-inview="fadeInUp" data-delay="400">
        <div
          v-for="(item, i) in $t('dink_ete_2022.calendar_events').filter(
            (i) => calendarActiveFilter === 'Tous' || calendarActiveFilter === 'All' || i.filter.includes(calendarActiveFilter)
          )"
          :key="i"
          class="event"
          @click.prevent="
            toggleDynamicSidePanel(
              true,
              $t('dink_ete_2022.calendar_events').filter((i) => calendarActiveFilter === 'Tous' || calendarActiveFilter === 'All' || i.filter.includes(calendarActiveFilter)),
              i
            )
          "
        >
          <div class="img">
            <img :src="item.img" :alt="item.titre" />
          </div>
          <div class="data">
            <div class="left">
              <p class="regular-text name">{{ item.titre }}</p>
              <p class="regular-text">{{ item.date }}</p>
            </div>
            <svg class="plus" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 7.4349V4.54294H4.54294V0H7.45706V4.54294H12V7.4349H7.45706V12H4.54294V7.4349H0Z" />
            </svg>
          </div>
        </div>
      </div>
    </section>

    <section class="activities_wrap">
      <img src="@/assets/img/curve-bleu.svg" alt="curve" class="curve" />
      <div class="intro" data-inview="fadeInUp" data-delay="200">
        <h2 class="medium-title">{{ $t('site_ete_2022.sejour_title') }}</h2>
        <p class="regular-text">
          {{ $t('site_ete_2022.sejour_desc') }}
        </p>
      </div>

      <!-- CARROUSEL ACTIVITY -->
      <div class="activities_list">
        <div v-for="(item, i) in $t('dink_ete_2022_activity')" :key="i" class="activity" data-inview="fadeInUp" :data-delay="3 + 1 + '00'">
          <a v-if="item.video" :href="item.video" class="glightbox img">
            <img src="@/assets/img/play.svg" alt="Play" class="play" />
            <div class="overlay"></div>
            <img :src="item.img" class="activity-img" alt="Montagne Tremblant" />
          </a>
          <div v-else-if="!item.video" class="img no_click">
            <div class="overlay"></div>
            <img :src="item.img" class="activity-img" alt="Montagne Tremblant" />
          </div>
          <div class="data">
            <div v-html="item.titre"></div>
            <a
              href="#"
              class="cta blanc-bleu"
              @click.prevent="
                toggleDynamicSidePanel(
                  true,
                  $t('dink_ete_2022_activity').filter((i) => calendarActiveFilter === 'Tous' || calendarActiveFilter === 'All' || i.filter === calendarActiveFilter),
                  i
                )
              "
              >{{ $t('site_ete_2022.more') }}</a
            >
          </div>
        </div>
      </div>

      <h2 class="medium-title separator" id="secteurs" data-inview="fadeInUp" data-delay="200">{{ $t('site_ete_2022.secteur_title') }}</h2>

      <!-- CARROUSEL SECTEURS -->
      <div class="activities_list">
        <div v-for="(item, i) in $t('dink_ete_2022_secteurs')" :key="i" class="activity" data-inview="fadeInUp" :data-delay="3 + 1 + '00'">
          <a v-if="item.video" :href="item.video" class="glightbox img">
            <img src="@/assets/img/play.svg" alt="Play" class="play" />
            <div class="overlay"></div>
            <img :src="item.img" class="activity-img" alt="Montagne Tremblant" />
          </a>
          <div v-else-if="!item.video" class="img no_click">
            <div class="overlay"></div>
            <img :src="item.img" class="activity-img" alt="Montagne Tremblant" />
          </div>
          <div class="data">
            <p class="big">{{ item.titre }}</p>
            <a
              href="#"
              class="cta blanc-bleu"
              @click.prevent="
                toggleDynamicSidePanel(
                  true,
                  $t('dink_ete_2022_secteurs').filter((i) => calendarActiveFilter === 'Tous' || calendarActiveFilter === 'All' || i.filter === calendarActiveFilter),
                  i
                )
              "
              >{{ $t('site_ete_2022.more') }}</a
            >
          </div>
        </div>
      </div>
    </section>

    <section class="bloc_wrap">
      <div class="bloc" data-inview="fadeInUp" data-delay="200">
        <div class="img">
          <img v-if="pageData && $route && $route.meta.lang === 'fr'" src="@/assets/img/politique-flexible-fr.jpg" alt="" />
          <img v-else-if="pageData && $route && $route.meta.lang === 'en'" src="@/assets/img/politique-flexible-en.jpg" alt="" />
        </div>
        <div class="data">
          <h3 class="small-title">{{ $t('site_ete_2022.politique_title') }}</h3>
          <a href="#" class="cta blanc-bleu politique-panel" @click.prevent="showpolitiquepanel">{{ $t('site_ete_2022.more') }}</a>
        </div>
      </div>
      <div class="bloc" data-inview="fadeInUp" data-delay="300">
        <div class="img">
          <img src="@/assets/img/ecoresponsabilite-ete-fr.jpg" alt="" />
        </div>
        <div class="data">
          <h3 class="small-title" v-html="$t('site_ete_2022.eco_small_title')"></h3>
          <a href="#" class="cta blanc-bleu eco-panel" @click.prevent="showecopanel">{{ $t('site_ete_2022.more') }}</a>
        </div>
      </div>
    </section>

    <news-letter></news-letter>

    <div v-if="$route.meta.lang === 'en'" class="logo-partenaire">
      <a v-if="$route.meta.lang === 'fr'" href="https://www.laurentides.com/fr" target="_blank"
        ><img src="@/assets/img/logo-tl-fr.png" alt="Logo Tourisme Laurentides"
      /></a>
      <a v-else-if="$route.meta.lang === 'en'" href="https://www.laurentides.com/en" target="_blank"
        ><img src="@/assets/img/logo-tl-en.png" alt="Logo Tourisme Laurentides"
      /></a>
    </div>

    <div v-if="$route.query && $route.query.market === 'usa'" class="note-yahoo">
      <p class="regular-text">* Source - Yahoo Finance currency exchange for USD to CAD</p>
    </div>

    <div class="deal-popup" :class="{ active: dealpop, force: dealpopforce }">
      <div class="open_box" @click.prevent="toggledealpop">
        <img src="@/assets/img/arrow-left.svg" alt="Arrow" class="open"/>
        <p class="v_txt">{{ $t('site_ete_2022.show_offer') }}</p>
      </div>
      <img src="@/assets/img/arrow-right.svg" alt="Arrow" class="close" @click.prevent="toggledealpop" />
      <p class="small-title" v-html="$t('site_ete_2022.bh_lodging_pop')"></p>
      <a href="#" class="cta blanc-bleu" @click.prevent="showdealpanel">{{ $t('site_ete_2022.bh_lodging_pop_cta') }}</a>
    </div>

    <div class="overlay-form-loding ete_2022" :class="{ show: formlodging }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidelodgingform" />
      <div class="form-wrap">
        <h3 class="medium-title">{{ $t('site.reservez-long') }}</h3>

        <form
          action="https://reservations.tremblant.ca/Ecomm/Shop/Lodging/2624850/fr-CA/?_ga=2.247307652.1090954148.1629141557-1179124804.1583853558&_gl=1*t5gc5p*_ga*MTE3OTEyNDgwNC4xNTgzODUzNTU4*_ga_B1YVPN56M6*MTYyOTIyOTEzOC4xNC4wLjE2MjkyMjkxMzguMA"
          class="lodging-form"
          target="_blank"
        >
          <div class="input">
            <p class="regular-text">{{ $t('site.arrivee') }}</p>
            <date-picker
              v-model="time1"
              name="arrivaldate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'arrivaldate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.depart') }}</p>
            <date-picker
              v-model="time2"
              name="departuredate"
              :lang="this.datepickerLang"
              :disabled-date="disabledBeforeToday"
              :input-attr="{ name: 'departuredate', required: 'required' }"
              required
            ></date-picker>
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.adulte') }}</p>
            <input name="adultcount" type="number" value="2" min="1" required />
          </div>
          <div class="input">
            <p class="regular-text">{{ $t('site.enfant') }}</p>
            <input name="childCount" type="number" value="0" required />
          </div>
          <input type="submit" class="cta submit blanc-rouge" :value="$t('site.reservez-long')" />
        </form>
      </div>
      <div class="opacity" @click.prevent="hidelodgingform"></div>
    </div>

    <div class="overlay-side-panel ete_2022" :class="{ show: politiquepanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidepolitiquepanel" />
      <div class="side-panel">
        <div v-if="pageData && $route && $route.meta.lang === 'fr'" class="img politique_flexible_fr"></div>
        <div v-else-if="pageData && $route && $route.meta.lang === 'en'" class="img politique_flexible_en"></div>
        <div class="data">
          <h3 class="small-title">{{ $t('site_ete_2022.politique_title') }}</h3>
          <div v-html="$t('site_ete_2022.politique_data')"></div>
        </div>
      </div>
      <div class="opacity" @click.prevent="hidepolitiquepanel"></div>
    </div>

    <div class="overlay-side-panel ete_2022" :class="{ show: ecopanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hideecopanel" />
      <div class="side-panel">
        <div class="img eco"></div>
        <div class="data">
          <h3 class="small-title" v-html="$t('site_ete_2022.eco_small_title')"></h3>
          <p class="regular-text">{{ $t('site_ete_2022.eco_intro_txt') }}</p>
          <p class="regular-text bold">{{ $t('site_ete_2022.eco_saviez_vous') }}</p>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_1')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_2')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_3')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_4')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_5')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_6')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_7')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_8')"></p>
          </div>
          <div class="note">
            <p class="regular-text" v-html="$t('site_ete_2022.eco_point_9')"></p>
          </div>
        </div>
      </div>
      <div class="opacity" @click.prevent="hideecopanel"></div>
    </div>

    <div class="overlay-side-panel ete_2022" :class="{ show: dealpanel }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="hidedealpanel" />
      <div class="side-panel deal">
        <div class="img ete_2022_bighit_dink"></div>
        <div class="data">
          <div v-html="$t('site_ete_2022.bh_lodging_data')"></div>
          <a href="#" class="cta blanc-rouge" @click.prevent="showlodgingform2">{{ $t('site.reservez-long') }}</a
          ><br />
          <a v-if="$route.meta.lang === 'fr'" href="/ete/2022/bighit/terms-hebergement/" class="regular-text modalite">{{ $t('site.modalites') }}</a>
          <a v-else-if="$route.meta.lang === 'en'" href="/en/ete/2022/bighit/terms-hebergement/" class="regular-text modalite">{{
            $t('site.modalites')
          }}</a>
        </div>
      </div>
      <div class="opacity" @click.prevent="hidedealpanel"></div>
    </div>

    <!-- Dynamic Side Panel -->
    <div class="overlay-side-panel dynamic-side-panel ete_2022" :class="{ show: dynamicPanelIsOpen }">
      <img src="@/assets/img/close.svg" alt="close" class="close" @click.prevent="toggleDynamicSidePanel(false)" />
      <div class="side-panel">
        <div
          v-if="dynamicPanelIsOpen && dynamicPanelItems && dynamicPanelItems.length"
          class="img"
          :style="'background-image:url(' + dynamicPanelItems[dynamicPanelPos].img + ')'"
        >
          <div v-if="dynamicPanelItems.length > 1" class="arrow-wrap prev" @click.prevent.stop="dynamicSidePanelGoTo('prev')">
            <img src="@/assets/img/prev.svg" alt="prev" />
          </div>
          <div v-if="dynamicPanelItems.length > 1" class="arrow-wrap next" @click.prevent.stop="dynamicSidePanelGoTo('next')">
            <img src="@/assets/img/next.svg" alt="next" />
          </div>
        </div>
        <div class="data">
          <span v-if="dynamicPanelIsOpen && dynamicPanelItems && dynamicPanelItems.length" v-html="dynamicPanelItems[dynamicPanelPos].data"></span>
        </div>
      </div>
      <div class="opacity" @click.prevent="toggleDynamicSidePanel(false)"></div>
    </div>
  </div>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'

import * as LottiePlayer from '@lottiefiles/lottie-player'

import GLightbox from 'glightbox'
import DatePicker from 'vue2-datepicker'

import 'vue2-datepicker/index.css'
import trans from '@/translations/index'

import NewsLetter from '@/components/NewsLetter'

import getDocById from '@/firebase/init'

export default {
  components: { NewsLetter, DatePicker },

  head() {
    return {
      title: {
        inner: this.$t(`site_ete_2022.bh_lodging_meta_title`),
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: this.$t(`site_ete_2022.bh_lodging_meta_title`) },
        {
          name: 'description',
          content: this.$t(`site_ete_2022.bh_lodging_meta_desc`),
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: this.$t(`site_ete_2022.bh_lodging_meta_title`) },
        { name: 'twitter:card', content: 'Tremblant' },
        {
          n: 'twitter:description',
          c: this.$t(`site_ete_2022.bh_lodging_meta_desc`),
        },
        // Facebook / Open Graph
        { property: 'og:title', content: this.$t(`site_ete_2022.bh_lodging_meta_title`) },
        { property: 'og:site_name', content: 'Tremblant' },
        {
          property: 'og:description',
          content: this.$t(`site_ete_2022.bh_lodging_meta_desc`),
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://promo.tremblant.ca/vue-app-static-img/ete-2022/bighit/dink.jpg',
        },
        {
          name: 'twitter:image',
          content: 'https://promo.tremblant.ca/vue-app-static-img/ete-2022/bighit/dink.jpg',
        },
      ],
    }
  },

  props: {
    pageData: {
      type: Object,
      required: true,
      default: null,
    },
  },

  data() {
    return {
      formlodging: false,
      sidepanel: false,
      politiquepanel: false,
      ecopanel: false,
      dealpanel: false,
      lottieInstance: null,
      lightboxelement: null,
      datepickerLang: null,
      dealpop: false,
      dealpopforce: false,
      time1: new Date(),
      time2: null,
      calendarActiveFilter: 'Tous',
      dynamicPanelIsOpen: false,
      dynamicPanelPos: 0,
      dynamicPanelItems: [],
      marketPrice: null,
    }
  },

  watch: {
    $route: {
      async handler($route) {
        if (!isNil($route)) {
          const marketPrice = await getDocById()

          if (marketPrice) {
            this.marketPrice = marketPrice.currentPrice
          }

          if ($route.meta.lang === 'en') {
            this.calendarActiveFilter = 'All';
          }

          if ($route.hash) {
            setTimeout(() => {
              if ($route.hash === '#events') {
                const $target = document.querySelector('#events')
                $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
              }
              if ($route.hash === '#quoi-faire') {
                const $target = document.querySelector('#quoi-faire')
                $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
              }
              if ($route.hash === '#secteurs') {
                const $target = document.querySelector('#secteurs')
                $target.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
              }
              if ($route.hash === '#offre') {
                this.dealpanel = true
              }
            }, 755)
          }
        }
      },
      immediate: true,
    },
  },

  computed: mapState('app', ['appTitle']),

  mounted() {
    this.lottieInstance = LottiePlayer

    this.lightboxelement = GLightbox
    GLightbox()

    const dt = new Date()
    dt.setDate(dt.getDate() + 1)
    this.time2 = dt

    this.datepickerLang = trans.fr.datepicker
    if (this.$route.meta.lang === 'en') {
      this.datepickerLang = trans.en.datepicker
    }
  },

  created() {
    window.addEventListener('scroll', () => {
      this.actiondealpop()
    })
  },

  methods: {
    togglecalendarFiler(value) {
      this.calendarActiveFilter = value
    },
    toggleDynamicSidePanel(value, items = [], pos = 0) {
      this.dynamicPanelIsOpen = value
      this.dynamicPanelItems = items
      this.dynamicPanelPos = pos
    },
    dynamicSidePanelGoTo(action) {
      if (action === 'prev') {
        if (this.dynamicPanelPos === 0) {
          this.dynamicPanelPos = this.dynamicPanelItems.length - 1
        } else {
          this.dynamicPanelPos -= 1
        }
      } else if (this.dynamicPanelPos === this.dynamicPanelItems.length - 1) {
        this.dynamicPanelPos = 0
      } else {
        this.dynamicPanelPos += 1
      }
    },
    showlodgingform() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
      }
    },
    showlodgingform2() {
      if (this.formlodging) {
        this.formlodging = false
      } else {
        this.formlodging = true
        this.dealpanel = false
      }
    },
    hidelodgingform() {
      this.formlodging = false
    },
    toggledealpop() {
      if(this.dealpopforce === false) {
        this.dealpopforce = true
      } else {
        this.dealpopforce = false
      }
    },
    actiondealpop() {
      const st = document.documentElement.scrollTop
      const value = document.querySelector('#quoi-faire').offsetTop

      if (st >= value) {
        this.dealpop = true
      } else {
        if(this.dealpopforce) {
          this.dealpopforce = false
        }
        this.dealpop = false
      }
    },
    showecopanel() {
      if (this.ecopanel) {
        this.ecopanel = false
      } else {
        this.ecopanel = true
      }
    },
    hideecopanel() {
      this.ecopanel = false
    },
    showdealpanel() {
      if (this.dealpanel) {
        this.dealpanel = false
      } else {
        this.dealpanel = true
      }
    },
    hidedealpanel() {
      this.dealpanel = false
    },
    disabledBeforeToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    showpolitiquepanel() {
      if (this.politiquepanel) {
        this.politiquepanel = false
      } else {
        this.politiquepanel = true
      }
    },
    hidepolitiquepanel() {
      this.politiquepanel = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
